<template lang="html">
  <div class="conocenos uk-container">
    <div uk-grid>
      <div class="uk-width-1-2@m">
        <p class="uk-text-center"><img src="@/assets/img/logocristafin.png" class="img-logo"></p>
      </div>
      <div class="uk-width-1-2@m">
        <p class="uk-text-center"><img src="@/assets/img/group.png" class="img-group"></p>
        <h1 class="uk-text-center title crista-text-primary">¿Quiénes somos?</h1>
      </div>
      <h3 class="crista-text-primary uk-margin">Somos una organización con mas 11 años en el mercado financiero,
          participando en la colocación de créditos para capital de trabajo para
          PYME y Desarrollo Inmobiliario, nuestra empresa esta fortalecida con la
          experiencia de sus socios y consejeros con mas de 20 anos de
          experiencia financiera.
      </h3>
    </div>
    <div uk-grid>
      <div class="uk-width-1-3@m">
        <p class="uk-text-center"><img src="@/assets/img/mision.png" class="iconsa"></p>
        <h1 class="crista-text-secondary uk-text-center text-iconsa crista-text-preparatory"><b>MISIÓN</b></h1>
        <h3 class="uk-text-center">Ofrecer servicios financieros que fomenten y apoyen a las empresas, para desarrollar sus proyectos, impulsar el crecimiento y aumentar la rentabilidad de sus negocios.</h3>
      </div>
      <div class="uk-width-1-3@m">
        <p class="uk-text-center"><img src="@/assets/img/vision.png" class="iconsa"></p>
        <h1 class="crista-text-secondary uk-text-center text-iconsa crista-text-preparatory"><b>VISIÓN</b></h1>
        <h3 class="uk-text-center">Ser de las principales instituciones financieras en colocación de crédito en el mercado mexicano, con los productos financieros más innovadores en el sistema financiero.</h3>
      </div>
      <div class="uk-width-1-3@m">
        <p class="uk-text-center"><img src="@/assets/img/valores.png" class="iconsa"></p>
        <h1 class="crista-text-secondary uk-text-center text-iconsa crista-text-preparatory"><b>VALORES</b></h1>
        <h3 class="uk-text-center"><b>RESPONSABILIDAD:</b> Cumplimos nuestros compromisos en tiempo y forma y como lo hemos establecido, la palabra es lo más importante.<br><br> <b>RESPETO:</b> Es la importancia de dar su lugar a cada quien, para nosotros todos son importantes y todas las ideas tienen valor.<br><br> <b>INTEGRIDAD:</b> Somos comprometidos con lo que hacemos y tenemos un propósito principal que es ayudar al prójimo.<br><br> <b>HONESTIDAD:</b> Somos una empresa transparente, sincera y congruente en lo que hacemos y decimos.<br><br> <b>COMPETITIVIDAD:</b> Tenemos nuestras metas a corto, mediano y largoplazo; y así como lo hemos hecho nosotros queremos ayudarte a que tus metas sean claras y alcances tus objetivos.</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.img-group{
  width: 50%;
  margin-top: 7%;
}
.title{
  font-weight: bold;
}
.iconsa{
  width:40%;
}
.text-iconsa{
  margin-top:0px;
}
.conocenos{
  margin-bottom:35px;
}
@media only screen and (max-width: 945px) {
  .img-logo{
    width: 50%;
    margin-top: 7%;
  }
}
@media only screen and (min-width: 945px) {
  .img-logo{
    width: 50%;
    margin-top: 7%;
  }
}
</style>
