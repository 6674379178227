<template lang="html">
  <div class="footer-section">
    <div uk-grid>
      <div class="uk-width-1-4@m">
        <div uk-grid>
          <div class="uk-width-1-2@m"></div>
          <div class="uk-width-1-2@m">
            <h1 class="title-text">Sitio</h1>
            <ul class="menu-text">
              <li><router-link to="/conocenos" class="footer-link">CONÓCENOS</router-link></li>
              <li><router-link to="/credito" class="footer-link">CRÉDITO SIMPLE</router-link></li>
              <li><router-link to="/contacto" class="footer-link">CONTACTO</router-link></li>
              <li><router-link to="/alianzas" class="footer-link">ALIANZAS</router-link></li>
              <li><a href="https://cristafin.mx/avisos/cartel_comisiones_ley_para_la_transparencia_y_ordenamiento_de_los _servicios_financieros.pdf" class="footer-link">COMISIONES</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="uk-width-1-5 uk-text-center images-container">
        <p class="uk-visible@m"><a href="https://www.condusef.gob.mx/"><img src="@/assets/img/logo-condusef.png" class="logo-image"></a></p>
        <p class="uk-visible@m"><a href="https://www.buro.gob.mx/"><img src="@/assets/img/buro-entidades-financieras.png" class="logo-image"></a></p>
      </div>
      <div class="uk-width-1-5@m uk-text-right@m uk-margin-left uk-margin-remove-left@m uk-visible@m">
        <h1 class="title-text">Contáctanos</h1>
        <p class="text-section">Paseo San Isidro 400 Pte Local 11 Interior 212<br>
          Barrio de Santa Cruz<br>
           Metepec, Edo. Mex. C.P.52140<br>
            Tel. (722) 2627532<br>
            info@cristafin.mx</p>
      </div>
      <div class="uk-width-1-3 uk-text-center images-container">
        <p class="uk-visible@m"><img src="@/assets/img/iconocritafingris.png" class="logo-image"></p>
        <p class="uk-visible@m"><img src="@/assets/img/logocristafinbalcno.png" class="logo-image"></p>
      </div>
    </div>
    <div uk-grid>
      <div class="uk-width-1-4@m">
        <router-link to="/"><span uk-icon="icon: instagram" class="nav-icons"></span></router-link>
        <router-link to="/"><span uk-icon="icon: linkedin" class="nav-icons"></span></router-link>
        <router-link to="/"><span uk-icon="icon: facebook" class="nav-icons"></span></router-link>
      </div>
      <div class="uk-width-1-2@m">
        <h4 class="copyright uk-text-center">Copyright ©2021 All rights reserved</h4>
      </div>
      <div class="uk-width-1-4@m"></div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.footer-section{
  background:#9b8249;
  margin-bottom:10px !important;
  padding-bottom: 20px;
  padding-top: 35px;
}
.title-text{
  color:#020a24;
  font-weight: bold;
  padding-left: 18px;
  margin-bottom: 10px;
}
.menu-text{
  color:white;
  margin-top:0px !important;
}
.menu-text > li{
  height: 50px;
}
.text-section{
  color:#020a24;
  font-weight: bold;
  margin-top: 0px;
  line-height: 30px;
}
.logo-image{
   width: 50%;
}
.images-container{
  margin-top: 25px;
}
.nav-icons{
  padding-left:40px;
  color:#fff !important;
}
.copyright{
  color:white !important;
  letter-spacing: 5px;
}
.footer-link{
  text-decoration: none !important;
  color:#020a24 !important;
  font-weight: bold;
}
@media only screen and (max-width: 945px) {
  .copyright{
    color:white !important;
    letter-spacing: 0px;
  }
}
@media only screen and (min-width: 945px) {
  .copyright{
    color:white !important;
    letter-spacing: 5px;
  }
}
</style>
