<template>
  <div id="home">
    <div class="first-section" uk-grid>
      <div class="uk-width-1-3@m uk-width-1-6@s first-grid" >
        <p class="uk-text-center"><img src="@/assets/img/iconologocritaf.png" class="logo-crist-principal"></p>
      </div>
      <div class="uk-width-1-3@m uk-width-5-6@s second-grid uk-margin-left uk-margin-right uk-margin-remove-left@m uk-margin-remove-right@m">
        <h2 class="text-home text-home-bold">Cristaliza tus sueños...</h2>
        <p class="text-home text-home-message">Te ofrecemos opciones de crédito para capital de trabajo de tu desarrollo inmobiliario y/o empresa o negocio.</p>
      </div>
      <div class="uk-width-1-3@m uk-visible@m third-grid">
        <img src="@/assets/img/iconhome.png" style="width:100%">
      </div>
    </div>
    <div class="crista-background-primary crista-text-secondary testimoniales-bar uk-hidden">
      TESTIMONIALES
    </div>
    <div id="testimoniales">

      <div v-for="(section,i) in sections" :key="i">
        <router-link :to="section.link" style="text-decoration:none;">
          <div v-show="currentSection == i">
            <div class="">
              <h2 class="uk-text-center" style="margin-top: 20px;">{{section.title}}</h2>
            </div>
            <div class=" uk-margin" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="autoplay: true;autoplay-interval: 4000; ratio:19:6;">
                  <ul class="uk-slideshow-items">
                      <li v-for="(image,j) in section.images" :key="j">
                          <img :src="getImgUrl(image)" style="width:100%">
                      </li>
                  </ul>
                  <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                  <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
               </div>
              </div>
            </div>
          </div>
          </router-link>
      </div>

    </div>
    <div class="crista-background-primary bottom-bar">

    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      sections: [
        {
          title: 'Crédito PYME',
          text: '"Conseguí Capital de trabajo con <b>CRISTAFIN</b> para hacer crecer mi negocio"',
          link: '/credito',
          images: [
            'pyme1.jpg',
            'pyme2.jpg',
            'pyme3.jpg'
          ]
        },
        {
          title: 'Crédito Desarrollo inmobiliario',
          text: '"Mediante <b>CRISTAFIN</b> obtuve crédito para construir mi desarrollo inmobiliario."',
          link: '/credito',
          images: [
            'Dinmobili1.jpg',
            'Dinmobili2.jpg',
            'Dinmobili3.jpg'
          ]
        }
      ],
      currentSection: 0,
      counter: 0
    }
  },
  methods: {
    getImgUrl: function (pic) {
      return require('@/assets/img/'+pic)
    },
    init: function () {
      setInterval(this.count,1000)
    },
    count: function () {
      this.counter += 1
      if(this.counter == 12){
        if(this.currentSection == this.sections.length-1){
          this.currentSection = 0
        }else{
          this.currentSection += 1
        }
        this.counter = 0
      }
    }
  },
  components: {
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>
  .text-home{
    color:#0a0d29;
  }
  .third-grid{
    padding: 0px !important;
  }
  .text-home-bold{
    font-weight: bold;
  }
  .text-home-message{
    font-size: 23px;
  }
  .testimoniales-bar{
    font-size: 23px;
    letter-spacing: 7px;
    padding: 6px;
    padding-left: 29px;
  }

  @media only screen and (max-width: 945px) {
    .first-section{
      margin-bottom:50px;
    }
    .logo-crist-principal{
      width:15%
    }
  }
  @media only screen and (min-width: 945px) {
    .first-grid{
      padding-top: 98px;
    }
    .second-grid{
      padding-top: 100px;
    }
    .logo-crist-principal{
      width:59%
    }
  }
  .bottom-bar{
    width:100%;
    height: 100px;
  }
</style>
