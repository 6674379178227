<template>
  <div id="app">
    <nav-bar/>
    <router-view/>
    <bottom-nav/>
    <modal-atencion/>
  </div>
</template>

<script>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import NavBar from '@/components/navbar.vue'
import BottomNav from '@/components/footer.vue'
import ModalAtencion from '@/components/modalAtencion.vue'
UIkit.use(Icons)
export default {
  name: 'App',
  components: {
    NavBar,
    BottomNav,
    ModalAtencion
  }
}
</script>

<style>
@import '../node_modules/uikit/dist/css/uikit.css';
@import 'assets/css/style.css';
</style>
