<template lang="html">
  <div class="">
    <div class="uk-visible@m">
    <nav class="uk-navbar-container" style="padding-top: 10px;" uk-navbar>
        <div class="uk-navbar-left">
            <ul class="uk-navbar-nav nav-navbar">
                <li class="logo-nav">
                  <router-link to="/"><img src="@/assets/img/logocristafinhome.png"></router-link>
                </li>
                <li class="button-container-nav">
                  <router-link to="/conocenos"><span class="cris-button-nav uk-button uk-button-primary">CONÓCENOS</span></router-link>
                </li>
                <li class="button-container-nav">
                  <router-link to="/credito"><span class="cris-button-nav uk-button uk-button-primary">CRÉDITO SIMPLE</span></router-link>
                </li>
                <li class="button-container-nav">
                  <router-link to="/contacto"><span class="cris-button-nav uk-button uk-button-primary">CONTACTO</span></router-link>
                </li>
                <li class="button-container-nav">
                  <router-link to="/alianzas"><span class="cris-button-nav uk-button uk-button-primary">ALIANZAS</span></router-link>
                </li>
                <li class="button-container-nav" @click="toggleAvisos" style="margin-top: 40px;">
                  <span class="cris-button-nav uk-button uk-button-primary">Avisos CONDUSEF</span>
                </li>
                <ul v-show="showAvisos" style="padding-left: 30px;margin-top: 85px;margin-left: -427px;">
                  <li class="avisos"><a href="https://cristafin.mx/avisos/condusef/Cartel buro de entidades financieras.pdf" target="_blank"><span class="cris-button-nav uk-button uk-button-primary" style="font-size: 12px;">Buró de Entidades Financieras</span></a></li>
                  <li class="avisos"><a href="https://cristafin.mx/avisos/condusef/UNE_MODAVI5768-3012023-132753.pdf" target="_blank"><span class="cris-button-nav uk-button uk-button-primary" style="font-size: 12px;">UNE Unidad Especializada de Atención a Usuarios</span></a></li>
                  <li class="avisos"><a href="https://cristafin.mx/avisos/condusef/AvisoSD5768-1.pdf" target="_blank"><span class="cris-button-nav uk-button uk-button-primary" style="font-size: 12px;">Aviso de datos de Despachos de Cobranza</span></a></li>
                  
                </ul>
            </ul>
        </div>
    </nav>
    <div class="social-nav">
      <router-link to="/"><span uk-icon="icon: instagram" class="nav-icons"></span></router-link>
      <router-link to="/"><span uk-icon="icon: linkedin" class="nav-icons"></span></router-link>
      <router-link to="/"><span uk-icon="icon: facebook" class="nav-icons"></span></router-link>
    </div>
    </div>
    <nav class="uk-navbar uk-navbar-container uk-margin uk-hidden@m">
        <div class="uk-navbar-left">
            <a class="uk-navbar-toggle" uk-navbar-toggle-icon href="#" @click="showMenu()"></a>
        </div>
    </nav>
    <div id="offcanvas-menu" uk-offcanvas="overlay: true">
    <div class="uk-offcanvas-bar">
            <button class="uk-offcanvas-close" type="button" uk-close></button>
            <div class="uk-width-1-2@s uk-width-2-5@m">
                <ul class="uk-nav uk-nav-default">
                    <li class="uk-active"><a href="#"></a></li>
                    <li style="width:100%%;">
                      <router-link to="/"><img src="@/assets/img/logocristafinhome.png"></router-link>
                    </li>
                    <li class="button-container-nav">
                      <router-link to="/conocenos"><span>CONÓCENOS</span></router-link>
                    </li>
                    <li class="button-container-nav">
                      <router-link to="/credito"><span>CRÉDITO SIMPLE</span></router-link>
                    </li>
                    <li class="button-container-nav">
                      <router-link to="/contacto"><span>CONTACTO</span></router-link>
                    </li>
                    <li class="button-container-nav">
                      <router-link to="/alianzas"><span>ALIANZAS</span></router-link>
                    </li>
                    <li class="button-container-nav"><a href="https://cristafin.mx/avisos/condusef/Cartel buro de entidades financieras.pdf" target="_blank"><span class="cris-button-nav uk-button uk-button-primary" style="font-size: 12px;">Buró de Entidades Financieras</span></a></li>
                    <li class="button-container-nav"><a href="https://cristafin.mx/avisos/condusef/UNE_MODAVI5768-3012023-132753.pdf" target="_blank"><span class="cris-button-nav uk-button uk-button-primary" style="font-size: 12px;">UNE Unidad Especializada de Atención a Usuarios</span></a></li>
                    <li class="button-container-nav"><a href="https://cristafin.mx/avisos/condusef/AvisoSD5768-1.pdf" target="_blank"><span class="cris-button-nav uk-button uk-button-primary" style="font-size: 12px;">Aviso de datos de Despachos de Cobranza</span></a></li>
                </ul>
            </div>
        </div>
    </div>
  </div>


</template>

<script>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);
export default {
  name:'NavBar',
  data () {
    return { showAvisos: false}
  },
  methods: {
    showMenu: function () {
      var element = document.getElementById('offcanvas-menu')
      UIkit.offcanvas(element).show();
    },
    toggleAvisos() {
      this.showAvisos = !this.showAvisos
    }
  },
  mounted() {
  }
}



</script>


<style lang="css" scoped>
.avisos{
  background-color: #191a36;
    color: #fff;
    width: 369px;
    border: 1px solid transparent;
}
.uk-navbar-container {
  background-image: linear-gradient(#191a36, #2b2d5ffa,#191a36) !important;
}
.logo-nav{
  width: 27% !important;
}
.cris-button-nav{
  background: #191a36;
  border-radius: 50px;
  color:#efce7cd1 !important;
  padding: 0 30px;
}
.cris-button-nav:hover{
  background: #2b2d5ffa;
}
.button-container-nav{
  margin-top:1.5%;
}
.social-nav{
  background: #191a36;
  text-align: right;
  padding-bottom: 8px;
  padding-right: 21px;
}
.nav-icons{
  padding-left:20px;
  color:#efce7cd1 !important;
}
.nav-navbar{
  height: 84px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}

.dropdown-menu a {
  display: block;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-menu a:hover {
  background-color: #f8f9fa;
}

.dropdown-menu.show {
  display: block;
}
</style>
