<template lang="html">
  <div class="Contacto uk-container">
    <p class="uk-text-center uk-visible@m"><img src="@/assets/img/logocristafin.png" class="img-logo"></p>
    <h1 class="uk-text-center crista-text-preparatory">CONTACTO</h1>
    <div v-if="sended">
      <h1>Gracias por escribirnos, en unos instantes te contactaremos</h1>
    </div>
    <div v-if="!sended" uk-grid>
      <div class="uk-width-1-2@m">
        <p class="uk-text-center"><img src="@/assets/img/contacto.png" class="image-contacto"></p>
      </div>
      <div class="uk-width-1-2@m">
        <p class="uk-text-right label crista-text-primary">Compañia</p>
        <input class="uk-input input-cristafin" v-model="company" value="" required>
        <p class="uk-text-right label crista-text-primary">Nombre</p>
        <input class="uk-input input-cristafin" v-model="name" value="" required>
        <p class="uk-text-right label crista-text-primary">Teléfono</p>
        <input class="uk-input input-cristafin" v-model="phone" value="" required>
        <p class="uk-text-right label crista-text-primary">Correo electrónico</p>
        <input class="uk-input input-cristafin" v-model="email" value="" required>
      </div>
    </div>
    <div v-if="!sended">
      <div style="margin-top:0;" uk-grid>
        <div class="uk-width-1-4@m"></div>
        <div class="uk-width-3-4@m">
          <p class="uk-text-right label crista-text-primary">Mensaje</p>
          <textarea v-model="message" class="uk-textarea uk-width-1-1 input-cristafin" rows="6" required></textarea>
        </div>
      </div>
      <p class="uk-text-right"><button id="button-submit" class="uk-button uk-button-primary crista-background-preparatory" @click="checkFields()">ENVIAR</button></p>
    </div>

    <div uk-grid>
      <div class="uk-width-1-2@m">
        <table>
          <tr>
            <td style="width: 40%;"><p class="uk-text-center"><img src="@/assets/img/icoubicacion.png" style="width: 50%;"></p></td>
            <td style="height: 200px;" class="crista-text-primary">Paseo San Isidro 400 Pte Local 11 Interior 212<br>
          Barrio de Santa Cruz<br>
           Metepec, Edo. Mex. C.P.52140<br>
            </td>
          </tr>
          <tr>
            <td style="width: 40%;"><p class="uk-text-center"><img src="@/assets/img/iconotelefono.png" style="width: 40%;"></p></td>
            <td><h3 class="crista-text-primary">(722) 262753</h3></td>
          </tr>
        </table>
      </div>
      <div class="uk-width-1-2@m">
        <table>
          <tr>
            <td style="width: 40%;"><p class="uk-text-center"><img src="@/assets/img/icomail.png" style="width: 58%;"></p></td>
            <td style="height: 200px;"><h3 class="crista-text-primary">info@cristafin.mx</h3></td>
          </tr>
        </table>
      </div>
    </div>
    <a href="https://www.google.com.mx/maps/place/Business+Center+Metepec/@19.2526017,-99.615667,17z/data=!4m7!3m6!1s0x85cd8bb8fe8f5317:0x2896cee242ede524!8m2!3d19.2527385!4d-99.6129954!15sCkpQYXNlbyBTYW4gSXNpZHJvIDQwMCBQdGUgQmFycmlvIGRlIFNhbnRhIENydXogTWV0ZXBlYywgRWRvLiBNZXguIEMuUC41MjE0MJIBD2J1c2luZXNzX2NlbnRlcuABAA!16s%2Fg%2F1tmpf832?entry=tts&shorturl=1" target="__blank">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3766.6839354650742!2d-99.615667!3d19.2526017!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd8bb8fe8f5317%3A0x2896cee242ede524!2sBusiness%20Center%20Metepec!5e0!3m2!1ses-419!2smx!4v1691803394943!5m2!1ses-419!2smx" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </a>
  </div>
</template>

<script>
import Axios from 'axios'
export default {
  name: 'contacto',
  data () {
    return {
      company: '',
      name: '',
      phone: '',
      email: '',
      message: ''
    }
  },
  methods: {
    checkFields: function () {
      if(this.company == '' || this.name == '' || this.phone == '' || this.email == '' || this.message == ''){
        alert('Por favor llena todos los campos antes de enviar')
      }else{
        var button = document.getElementById('button-submit')
        var inputs = document.getElementsByClassName('input-cristafin')
        console.log(inputs)
        var i = 0
        for(i=0; i<inputs.length; i++){
          inputs[i].disabled = true
        }
        button.innerHTML = 'Enviando'
        button.disabled = true
        this.sendEmail()
      }
    },
    sendEmail: function () {
      var vue = this
      Axios.post('https://app.cristafin.mx/mail/mail.php', {
        company: this.company,
        name: this.name,
        phone: this.phone,
        email: this.email,
        message: this.message
      })
      .then(function () {
        vue.$store.commit('EmailSended');
      })
      .catch(function (error) {
        alert('Lo sentimos, ocurrió un error')
        console.log(error);
      });
    }
  },
  computed: {
    sended: function () {
      return this.$store.state.sended
    }
  }
}
</script>

<style lang="css" scoped>
.img-logo{
  width: 20%;
  margin-top: 5%;
  letter-spacing: 20px;
}

.uk-input, .uk-textarea{
  border: 2px solid #b09452 !important;
}
.label{
  margin:0;
  margin-top: 15px;
  font-weight: bold;
}
.uk-button-primary{
  margin-top:20px;
}
.Contacto{
  margin-bottom:35px;
}
@media only screen and (max-width: 945px) {
  .image-contacto{
    width: 15%;
  }
}
@media only screen and (min-width: 945px) {
  .image-contacto{

  }
}
</style>
