<template lang="html">
  <div id="modal-atencion" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title">ATENCIÓN</h2>
        </div>
        <div class="uk-modal-body" uk-overflow-auto>
          <p>DERIVADO DEL SEGUIMIENTO Y AVISO A CONDUSEF Y USUARIOS,
            EN RELACION A LOS FRAUDES QUE ESTAN LLEVANDO A CABO DIVERSAS PERSONAS UTILIZANDO NUESTRA EMPRESA Y MARCA,
             LES INFORMAMOS QUE YA ESTA PUBLICADO EN EL BOLETIN MENSUAL DE LA PAGINA OFICIAL DE CONDUSEF,
             EL COMUNICADO QUE INDICA LAS EMPRESAS QUE ESTAMOS SIENDO OBJETO DE SUPLANTACION,
             FAVOR DE SEGUIR LAS INSTRUCCIONES QUE SE SEÑALAN EN EL SIGUIENTE ENLACE:</p>
             <p><a href="https://www.condusef.gob.mx/?p=contenido&idc=1816&idcat=1" target="_blank">https://www.condusef.gob.mx/?p=contenido&idc=1816&idcat=1</a></p>
             <p>TAMBIEN PUEDEN VERIFICAR EN EL PORTAL DE FRAUDES EN CONDUSEF
               EL REPORTE COMO EMPRESA QUE HA
                SIDO SUPLANTADA
                PARA MAYOR DETALLE ACCEDER AL SIGUIENTE ENLACE:</p>
              <p><a href="https://phpapps.condusef.gob.mx/fraudes_financieros/monitor.php" target="_blank">https://phpapps.condusef.gob.mx/fraudes_financieros/monitor.php</a></p>
        </div>

        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-primary uk-modal-close" @click="setCookie()" type="button">OK</button>
        </div>

    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
export default {
  name: 'ModalAtencion',
  mounted () {
    this.init()
  },
  methods: {
    getCookie: function (name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        }
        else
        {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end == -1) {
            end = dc.length;
            }
        }

        return decodeURI(dc.substring(begin + prefix.length, end));
    },
    init() {
        var cookie = this.getCookie("ModalAtention");
        if (cookie == null) {
            var element = document.getElementById('modal-atencion')
            UIkit.modal(element).show();
        }
    },
    setCookie: function () {
      return false
    }
  }
}
</script>

<style lang="css" scoped >

</style>
