<template lang="html">
  <div class="alianzas uk-container">
    <div uk-grid>
      <div class="uk-width-1-4@m uk-first-column uk-visible@m">
        <p class="uk-text-center" style="margin-top: 60px;">
          <img src="@/assets/img/iconologocritaf.png" class="img-logo">
        </p>
      </div>
      <div class="uk-width-1-2@m">
        <h1 class="crista-text-preparatory text-credito-head uk-text-center"><b>ALIANZAS</b></h1>
      </div>
      <div class="uk-width-1-4@m">
        <p class="uk-text-center uk-visible@m">
          <img src="@/assets/img/manos.png" class="img-dinero" style="margin-top: 96px;">
        </p>
      </div>
    </div>
    <div style="margin-top: 100px;">
      <p class="uk-text-center"><img src="@/assets/img/logoAGA.png" style="width:50%;"></p>
    </div>
    <div style="margin-top: 100px;">
      <p class="uk-text-center"><img src="@/assets/img/LOGOargentocap.png" style="width:40%;"></p>
    </div>

  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.alianzas{
  margin-bottom:35px;
}

@media only screen and (max-width: 945px) {
 .text-credito-head{
   margin-top: 0px;
 }
}
@media only screen and (min-width: 945px) {
 .text-credito-head{
   letter-spacing: 20px;
   margin-top: 127px;
 }
}
</style>
