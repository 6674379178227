<template lang="html">
  <div class="Credito">
    <div class="uk-container">
      <div uk-grid>
        <div class="uk-width-1-4@m">
          <p class="uk-text-center"><img src="@/assets/img/logocristafin.png" class="img-logo"></p>
        </div>
        <div class="uk-width-1-2@m">
          <h2 class="crista-text-primary text-credito-head"><b>El crédito simple que ofrecemos los subdividimos en dos productos bajo esta modalidad:</b></h2>
        </div>
        <div class="uk-width-1-4@m last-grid">
          <p class="uk-text-center uk-visible@m"><img src="@/assets/img/dineroicon.png" class="img-dinero"></p>
        </div>
      </div>
    </div>
    <img src="@/assets/img/barracredito1.png" class="uk-visible@m" style="width:100%;margin-top: 50px;">
    <div class="crista-background-primary crista-text-secondary credito-bar uk-hidden@m uk-margin">
      <div style="margin-left:10px">CRÉDITO PYME</div>
    </div>
    <div class="pymes-section uk-container">
      <h3 class="crista-text-primary">Crédito dirigido a las actividades productivas para la micro, pequeña y mediana empresa, de acuerdo a sus necesidades por lo que contamos con diferentes opciones:</h3>
      <ul>
        <li><h3 class="crista-text-primary"><b>Crédito simple</b> para la compra de activos y capital de trabajo: Financiamos la compra de activos y las actividades de la empresa, quedando como garantía un inmueble de la empresa o los accionistas.</h3></li>
        <li><h3 class="crista-text-primary"><b>Arrendamiento puro:</b> Financiamos la compra de activos, quedando estos como garantía.</h3></li>
        <li><h3 class="crista-text-primary"><b>Factoraje financiero:</b> Financiamos las facturas de sus principales clientes como garantía.</h3></li>
      </ul>
    </div>
    <img src="@/assets/img/barracredito2.png" class="uk-visible@m" style="width:100%;margin-top: 50px;">
    <div class="crista-background-primary crista-text-secondary credito-bar uk-hidden@m uk-margin">
      <div style="margin-left:10px">CRÉDITO DESARROLLO INMOBILIARIO</div>
    </div>
    <div class="pymes-section uk-container">
      <h3 class="crista-text-primary">Este tipo de crédito va dirigido a proyectos inmobiliarios pequeños y medianos en diferentes zonas del país,el respaldo del crédito es el propio terreno e inmueble.</h3>
      <ul>
        <li><h3 class="crista-text-primary"><b>Analizamos al desarrollador y proyecto</b></h3></li>
        <li><h3 class="crista-text-primary"><b>Contemplamos escenarios</b></h3></li>
        <li><h3 class="crista-text-primary"><b>El crédito se entrega por ministraciones</b></h3></li>
        <li><h3 class="crista-text-primary"><b>Tomamos en cuenta la zona donde se encuentra el desarrollo</b></h3></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      calculator: 1
    }
  },
  components: {
  }
}
</script>

<style lang="css" scoped>
.img-dinero{
  width: 40%;
  margin-top: 15%;
}
.divisor-pyme{
  font-size: 23px;
  letter-spacing: 7px;
  padding: 6px;
  padding-left: 29px;
  margin-top: 50px;
}
.pymes-section{
  margin-top: 30px;
}
.Credito{
  margin-bottom:35px;
}
.credito-bar{
  font-size: 30px;
}
@media only screen and (max-width: 945px) {
  .img-logo{
    width: 41%;
    margin-top: 0;
  }
  .text-credito-head{
    margin-top: 0px;
  }
  .last-grid{
    display: none;
  }
}
@media only screen and (min-width: 945px) {
  .img-logo{
    width: 100%;
    margin-top: 15%;
  }
  .text-credito-head{
    margin-top: 90px;
  }
}
</style>
