import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Conocenos from '../views/Conocenos.vue'
import Credito from '../views/Credito.vue'
import Contacto from '../views/Contacto.vue'
import Alianzas from '../views/Alianzas.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/conocenos',
    name: 'Conocenos',
    component: Conocenos
  },
  {
    path: '/credito',
    name: 'Credito',
    component: Credito
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/alianzas',
    name: 'Alianzas',
    component: Alianzas
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
